import React from 'react';
import { Card, styled } from '@material-ui/core'
import Logo_white from 'images/common/logo_white.png'
import Logo from 'images/common/logo_login.png'
import { useAppTheme } from 'app/providers/apptheme.provider'
import { useTranslation } from 'react-i18next'

const Container = styled('div')(() => ({
  display: 'grid',
  placeItems: 'center',
  height: '100vh'
}))

const Content = styled(Card)(() => ({
  padding: '2rem',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 24,
  '& > div > img': {
    height: 80,
    width: 'unset',
    maxWidth: '100%'
  },
  '& h1, & p': {
    margin: 0
  }
}))


export const MaintenanceView = (): JSX.Element => {
  const {isDarkMode} = useAppTheme()
  const {t} = useTranslation()

  return (<Container className={isDarkMode ? 'bg dark' : 'bg'}>
      <Content variant="outlined">
      <h1>{t('common.maintenanceView.website_under_maintenance')}</h1>
      <p>{t('common.maintenanceView.sorry_check_back_soon')}</p>
      <img src={isDarkMode ? Logo_white : Logo} className="logo" alt="" />
      </Content>
  </Container>
)
}
