import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {PlanningPeriodEnum} from "app/enums/planningPeriod.enum";
import {PlanningTypeEnum} from "app/enums/planningType.enum";
import {IPlanningDays, IPlanningService, IPlanningServices} from "app/models/planning.model";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@material-ui/lab";
import {useOnView} from "app/providers/onview.provider";

interface IProps {
    anchorEl: Element | null,
    setAnchorEl: Dispatch<SetStateAction<Element | null>>
    period: PlanningPeriodEnum
    setPeriod: Dispatch<SetStateAction<PlanningPeriodEnum | undefined>>
    setMyPlanning: Dispatch<SetStateAction<IPlanningDays>>
    currentIndex: number
    data: IPlanningDays
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: 12,
        fontWeight: 500
    },
    subLabel: {
        fontSize: 10,
        color: '#909090'
    },
    icon: {
        height: 14
    },
    rotate: {
        rotate: '180deg'
    },
    popover: {
        width: 300,
        height: 200,
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        gap: theme.spacing(2)
    },
    tabs: {
        cursor: 'pointer',
        alignItems: 'center',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        userSelect: 'none'
    },
    tabContent: {
        padding: theme.spacing(0.8),
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center',
        fontSize: 12
    },
    module: {
        display: 'flex',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        alignItems: 'center',
        minHeight: 34,
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
    },
    remoteColor: {
        backgroundColor: '#00ABC333',
    },
    officeColor: {
        backgroundColor: '#00C31433',
    },
    offColor: {
        backgroundColor: '#FDCA3933',
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        outline: `2px solid ${theme.palette.primary.main}`
    },
    afternoonBorder: {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
    },
    allDayBorder: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
    },
    customPopover: {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
    },
}))

const PopoverComponent = ({anchorEl, setAnchorEl, period, setPeriod, currentIndex, setMyPlanning, data}: IProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const [isOffice, setIsOffice] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [services, setServices] = useState<IPlanningServices>()
    const {availableServicesPlanningGetUseMutation} = useOnView()

    const handleClose = useCallback(() => {
        setAnchorEl(null)
        setIsOffice(false)
        setServices(undefined)
    }, [])

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleServices = useCallback(async () => {
        setIsLoading(true)
        const response = await availableServicesPlanningGetUseMutation?.mutateAsync({
            queryParams: {
                dayDate: data[currentIndex][period === PlanningPeriodEnum.FULL ? 0 : (period - 1)].dayDate,
                dayPeriod: period
            }
        })
        setServices(response)
        setIsLoading(false)
    }, [period, currentIndex, data])

    useEffect(() => {
        if(isOffice) {
           handleServices().then()
        }
    }, [period, isOffice, handleServices])

    const handleTab = useCallback(async (period) => {
        switch (period) {
            case PlanningPeriodEnum.AM:
                setPeriod(PlanningPeriodEnum.AM)
                break
            case PlanningPeriodEnum.PM:
                setPeriod(PlanningPeriodEnum.PM)
                break
            default:
                setPeriod(PlanningPeriodEnum.FULL)
        }
    }, [])

    const handleClick = useCallback(async (type: number) => {
        setIsOffice(type === PlanningTypeEnum.OFFICE)
        if(type === PlanningTypeEnum.OFFICE) {
            return
        }

        setMyPlanning(prevState => {
            const newPlanning = [...prevState]
            if (period === PlanningPeriodEnum.FULL) {
                newPlanning[currentIndex!][0].serviceId = null
                newPlanning[currentIndex!][1].serviceId = null
                newPlanning[currentIndex!][0].serviceLabel = null
                newPlanning[currentIndex!][1].serviceLabel = null
                newPlanning[currentIndex!][0].zoneLabel = null
                newPlanning[currentIndex!][1].zoneLabel = null
                newPlanning[currentIndex!][0].dayType = type
                newPlanning[currentIndex!][1].dayType = type
            } else {
                newPlanning[currentIndex!][(period - 1)].serviceId = null
                newPlanning[currentIndex!][(period - 1)].serviceLabel = null
                newPlanning[currentIndex!][(period - 1)].zoneLabel = null
                newPlanning[currentIndex!][(period - 1)].dayType = type
            }
            return newPlanning
        })
        handleClose()
    }, [period, currentIndex, handleClose])

    const handleService = useCallback((service: IPlanningService) => {
        setMyPlanning(prevState => {
            const newPlanning = [...prevState]
            // Si toute la journée
            if (period === PlanningPeriodEnum.FULL) {
                newPlanning[currentIndex!][0].dayType = PlanningTypeEnum.OFFICE
                newPlanning[currentIndex!][1].dayType = PlanningTypeEnum.OFFICE
                newPlanning[currentIndex!][0].serviceId = service.id
                newPlanning[currentIndex!][1].serviceId = service.id
                newPlanning[currentIndex!][0].serviceLabel = service.label
                newPlanning[currentIndex!][1].serviceLabel = service.label
                newPlanning[currentIndex!][0].zoneLabel = service.zoneName
                newPlanning[currentIndex!][1].zoneLabel = service.zoneName
            } else {
                newPlanning[currentIndex!][(period - 1)].dayType = PlanningTypeEnum.OFFICE
                newPlanning[currentIndex!][(period - 1)].serviceId = service.id
                newPlanning[currentIndex!][(period - 1)].serviceLabel = service.label
                newPlanning[currentIndex!][(period - 1)].zoneLabel = service.zoneName
            }
            return newPlanning
        })
        handleClose()
    }, [period, currentIndex, handleClose])

    const ChoiceOccupation = useCallback(() => {
        return (
            <Grid container style={{gap: 8}}>
                <Grid item xs={12} className={`${classes.module} ${classes.remoteColor}`} onClick={() => handleClick(PlanningTypeEnum.REMOTE)}>
                    <Typography variant="body1" className={classes.label}>
                        {t('common.myWeekView.remote')}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={`${classes.module} ${classes.officeColor}`} style={{justifyContent: 'space-between'}} onClick={() => handleClick(PlanningTypeEnum.OFFICE)}>
                    <div>
                        <Typography variant="body1" className={classes.label}>
                            {t('common.myWeekView.office')}
                        </Typography>
                    </div>
                    <ArrowBackIosIcon fontSize='small' className={`${classes.icon} ${classes.rotate}`}/>
                </Grid>
                <Grid item xs={12} className={`${classes.module} ${classes.offColor}`} onClick={() => handleClick(PlanningTypeEnum.OFF)}>
                    <Typography variant="body1" className={classes.label}>
                        {t('common.myWeekView.off')}
                    </Typography>
                </Grid>
            </Grid>
        )
    }, [t, handleClick])

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            classes={{
                paper: classes.customPopover
            }}
        >
            <Grid container className={classes.popover}>
                <Grid container alignItems="center">
                    {isOffice && (
                        <Grid item style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }} onClick={() => setIsOffice(false)}>
                            <ArrowBackIosIcon fontSize='small' className={`${classes.icon}`}/>
                        </Grid>
                    )}
                    <Grid item xs>
                        <Grid container alignItems="center" className={classes.tabs}>
                            <div style={{flex: 2}}
                                 className={`${classes.tabContent} ${period === PlanningPeriodEnum.FULL && classes.selected + ' ' + classes.allDayBorder}`}
                                 onClick={() => handleTab(PlanningPeriodEnum.FULL)}>
                                {t('common.myWeekView.popoverComponent.allDay')}
                            </div>
                            <Divider orientation="vertical" flexItem/>
                            <div
                                className={`${classes.tabContent} ${period === PlanningPeriodEnum.AM && classes.selected}`}
                                onClick={() => handleTab(PlanningPeriodEnum.AM)}>
                                {t('common.myWeekView.AM')}
                            </div>
                            <Divider orientation="vertical" flexItem/>
                            <div style={{flex: 1.5}}
                                 className={`${classes.tabContent} ${period === PlanningPeriodEnum.PM && classes.selected + ' ' + classes.afternoonBorder}`}
                                 onClick={() => handleTab(PlanningPeriodEnum.PM)}>
                                {t('common.myWeekView.PM')}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {!isOffice && (<ChoiceOccupation/>)}
                {isOffice && (
                    <Grid container style={{gap: 8, height: '100%', overflowY: 'scroll'}}>
                        {isLoading ? Array.from({length: 3}).map((_, index) => (<Skeleton key={index} variant="rect" width={'100%'} height={34}/>)) : (services !== undefined && services.map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} className={`${classes.module} ${classes.officeColor}`} style={{justifyContent: 'space-between'}} onClick={() => handleService(item)}>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography variant="body1" className={classes.label}>
                                                    {t('common.myWeekView.office')}
                                            </Typography>
                                            <Typography variant="body1" className={classes.label}>
                                                <span style={{color: '#909090', fontSize: 10}}>{item.zoneName}</span>
                                            </Typography>
                                        </div>
                                    <Typography variant="body1" style={{fontSize: 12}}>
                                        {item.label}
                                    </Typography>
                                </Grid>
                            )
                        }))}
                    </Grid>
                )}
            </Grid>
        </Popover>
    )
}

export default PopoverComponent
