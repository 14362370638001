import {ImageSchema} from "app/models/booking.model";
import {checkResponse, getRequestInit, getUrl} from "./api";

export const AssetsQuery = async (
    type: 'services' | 'service-types' ,
    id: number
): Promise<ImageSchema[]> => {
    return checkResponse(
        await fetch(
            getUrl(`assets/${type}/${id}?fallback=0`),
            getRequestInit(true)
        )
    )
}
