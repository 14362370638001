import {PlanningTypeEnum} from "app/enums/planningType.enum";
import {IMyPlannings, IPlanning, IPlanningDays, IPlannings} from "app/models/planning.model";

export const formatName = (type: PlanningTypeEnum) => {
    if(type === PlanningTypeEnum.REMOTE) {
        return 'common.myWeekView.remote'
    }

    return 'common.myWeekView.off'
}

export const formatBackgroundColor = (type: PlanningTypeEnum|null): string => {
    switch(type) {
        case PlanningTypeEnum.OFFICE:
            return '#00C31433'
        case PlanningTypeEnum.REMOTE:
            return '#00ABC333'
        case PlanningTypeEnum.OFF:
            return '#FDCA3933'
        default:
            return '#fff'
    }
}

export const myPlannings = (myPlannings: IMyPlannings) => {
    const plannings = []
    const days = myPlannings.days

    for(let i = 0; i < days.length; i+= 2) {

        const planning = days[i]
        const planning2 = days[i + 1]

        const planningAM = {} as IPlanning
        const planningPM = {} as IPlanning

        planningAM.dayDate = planning.dayDate
        planningAM.dayType = planning.dayType
        planningAM.dayPeriod = planning.dayPeriod
        planningAM.serviceId = planning.serviceId
        planningAM.serviceLabel = planning.serviceLabel
        planningAM.zoneLabel = planning.zoneName

        planningPM.dayDate = planning2.dayDate
        planningPM.dayType = planning2.dayType
        planningPM.dayPeriod = planning2.dayPeriod
        planningPM.serviceId = planning2.serviceId
        planningPM.serviceLabel = planning2.serviceLabel
        planningPM.zoneLabel = planning2.zoneName

        plannings.push([planningAM, planningPM])
    }
    return plannings
}

export const formattedForSending = (planning: IPlanningDays, isFavorite: boolean) => {
    return {
        isFavorite,
        days: planning.flatMap((planning: IPlannings) => planning.map((day: IPlanning) => {
            return {dayDate: day.dayDate, dayType: day.dayType, dayPeriod: day.dayPeriod, serviceId: day.serviceId}
        }))
    }
}
