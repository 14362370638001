import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography";
import React, {MouseEvent, useCallback, useEffect, useState} from "react";
import Box from '@material-ui/core/Box'
import {IMAGES} from "app/constants/images";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles} from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PopoverComponent from "../components/popover.component";
import WeekComponent from "../components/week.component";
import dayjs from 'dayjs'
import 'dayjs/locale/fr';
import {IMyPlanningBody, IMyPlannings, IPlanning, IPlanningDays, IPlannings} from "app/models/planning.model";
import {PlanningPeriodEnum} from "app/enums/planningPeriod.enum";
import {firstLetterUpper} from "app/helpers/formatter.helper";
import {useTranslation} from "react-i18next";
import OfficeSectionComponent from "../components/officeSection.component";
import {useOnView} from "app/providers/onview.provider";
import {formatBackgroundColor, formatName, myPlannings, formattedForSending} from "../helpers/formatter.helper";
import {Skeleton} from "@material-ui/lab";
import {PlanningTypeEnum} from "app/enums/planningType.enum";
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 206,
        height: 95,
        cursor: 'pointer'
    },
    title: {
        fontSize: 22
    },
    text: {
        fontWeight: 500,
        fontSize: 14,
        userSelect: 'none'
    },
    today: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    circularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    confirmButton: {
        color: 'white',
        position: 'relative'
    }
}))

const MyWeek = () => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const [period, setPeriod] = useState<PlanningPeriodEnum | undefined>()
    const [myPlanning, setMyPlanning] = useState<IPlanningDays>([])
    const [currentIndex, setCurrentIndex] = useState<number>()
    const {t} = useTranslation()
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const {myPlanningGetUseMutation, updateMyPlanningUseMutation, createMyPlanningUseMutation} = useOnView()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false)
    const [favorite, setFavorite] = useState<number>(0)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)

    const handleClick = useCallback((event: MouseEvent<HTMLElement>, period: number, index: number) => {
        setCurrentIndex(index)
        setPeriod(period)
        setAnchorEl(event.currentTarget)
    }, [])

    useEffect(() => {
        // TODO : Gérer la locale dynamiquement
        dayjs.locale('fr')

        const fetchData = async () => {
            const response = await fetchMyPlanning(undefined) as IMyPlannings
            setFavorite(Number(!response.hasFavorite))
            setIsEdit(!response.days.some((planning) => planning.clientPlanningId === null))
            setMyPlanning(myPlannings(response))
            setIsLoading(false)
        }
        fetchData().then()
    }, [])

    const fetchMyPlanning = useCallback(async (dayDate?: string) => {
        return myPlanningGetUseMutation?.mutateAsync(dayDate);
    }, [myPlanningGetUseMutation])

    const formatTitle = useCallback((date: string): string => {
        const dayDate = dayjs(date)
        const isToday = dayDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
        if (isToday) {
            return firstLetterUpper(dayDate.format('dddd D MMMM').replace(/(\d+)/, `<span class="${classes.today}">$1</span>`))
        }
        return firstLetterUpper(dayDate.format('dddd D MMMM'))
    }, [firstLetterUpper, dayjs])

    useEffect(() => {
        setIsDisabled(myPlanning.some((planning: IPlannings) => planning.some((day: IPlanning) => day.dayType === null)))
    }, [myPlanning])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleSubmit = useCallback(async () => {
        setIsLoadingSend(true)
        const data = formattedForSending(myPlanning, Boolean(favorite))
        let response;
        if (isEdit) {
            response = await updateMyPlanningUseMutation?.mutateAsync(data as IMyPlanningBody) as IMyPlannings
        } else {
            response = await createMyPlanningUseMutation?.mutateAsync(data as IMyPlanningBody) as IMyPlannings
            setIsEdit(!response.days.some((planning) => planning.clientPlanningId === null))
            setMyPlanning(myPlannings(response))
        }
        setFavorite(Number(!response.hasFavorite))
        handleClose()
        setIsLoadingSend(false)
    }, [myPlanning, favorite, isEdit, formattedForSending, handleClose])

    return (
        <Grid container style={{justifyContent: 'center'}}>
            <Box>
                <Grid item xs={12}>
                    <Box marginBottom={3}>
                        <Typography variant="h2" className={classes.title} gutterBottom component={'h2'}>
                            {t('common.myWeekView.title')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {isLoading ? (
                        <Box
                            alignItems={'center'}
                            flexDirection={'row'}
                            display={'flex'}
                            gridGap={7}
                            marginBottom={3}
                            justifyContent={'flex-end'}
                        >
                            <Skeleton variant="text" style={{fontSize: 14, width: 100, minHeight: 21}}/>
                        </Box>
                    ) : (
                        <WeekComponent
                            setIsEdit={setIsEdit}
                            setMyPlanning={setMyPlanning}
                            setIsLoading={setIsLoading}
                            fetchData={fetchMyPlanning}
                            data={myPlanning}
                        />
                    )}
                </Grid>
                <Grid container spacing={2} style={{marginBottom: 25}}>
                    {isLoading ? (Array.from({length: 5}).map((_, index) =>
                        <Grid item xs={12} sm={'auto'} key={index} style={{minHeight: 245}}>
                            <Skeleton variant="text" style={{fontSize: 14, marginBottom: 10, width: 100}}/>
                            <Box display={'flex'} flexDirection={'column'} gridGap={8}>
                                <Skeleton variant="rect" width={206} height={95}/>
                                <Skeleton variant="rect" width={206} height={95}/>
                            </Box>
                        </Grid>
                    )) : (
                        myPlanning.length > 0 && myPlanning.map((planning: IPlannings, index: number) => {
                                return (
                                    <Grid item xs={12} sm={'auto'} key={index} style={{minHeight: 245}}>
                                        <Typography variant="body1" style={{fontSize: 14, marginBottom: 10}}>
                                            <span dangerouslySetInnerHTML={{__html: formatTitle(planning[0].dayDate)}}/>
                                        </Typography>
                                        <Box display="flex" gridGap={8} flexDirection={'column'} position={'relative'}
                                             maxWidth={206}>
                                            <Box className={`${classes.item} shadow-color`}
                                                 style={{backgroundColor: formatBackgroundColor(planning[0].dayType)}}
                                                 onClick={(e) => handleClick(e, PlanningPeriodEnum.AM, index)}>
                                                {planning[0].dayType === PlanningTypeEnum.OFFICE ? (
                                                    <OfficeSectionComponent
                                                        officeLabel={planning[0].serviceLabel}
                                                        zoneLabel={planning[0].zoneLabel}
                                                    />
                                                ) : (
                                                    <Typography className={classes.text}>
                                                        {(planning[0].dayType === null) ? t('common.myWeekView.AM') : t(formatName(planning[0].dayType))}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box className={'position-center'}>
                                                <img src={IMAGES.PRESENCE.LUNCH} alt='lunch' width={23} height={23}/>
                                            </Box>
                                            <Box className={`${classes.item} shadow-color`}
                                                 style={{backgroundColor: formatBackgroundColor(planning[1].dayType)}}
                                                 onClick={(e) => handleClick(e, PlanningPeriodEnum.PM, index)}>
                                                {planning[1].dayType === PlanningTypeEnum.OFFICE ? (
                                                    <OfficeSectionComponent
                                                        officeLabel={planning[1].serviceLabel}
                                                        zoneLabel={planning[1].zoneLabel}
                                                    />
                                                ) : (
                                                    <Typography className={classes.text}>
                                                        {(planning[1].dayType === null) ? t('common.myWeekView.PM') : t(formatName(planning[1].dayType))}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            }
                        )
                    )}

                    <PopoverComponent
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        period={period!}
                        setPeriod={setPeriod}
                        setMyPlanning={setMyPlanning}
                        data={myPlanning}
                        currentIndex={currentIndex!}
                    />
                </Grid>
                <Grid item>
                    <Box display={'flex'} alignItems={'center'} marginBottom={2}>
                        {isLoading ? (
                            <Skeleton variant="rect" width={300} height={35}/>
                        ) : (
                            <>
                                <Typography variant="body1" className={classes.text}>
                                    Sauvegarder ce modèle en semaine régulière ?
                                </Typography>
                                <RadioGroup row aria-label="position" name="position" value={favorite}
                                            onChange={(e) => setFavorite(Number(e.target.value))}>
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio color="primary"/>}
                                        label="Oui"
                                        labelPlacement="start"
                                        classes={{label: classes.text}}
                                    />
                                    <FormControlLabel
                                        value={0}
                                        control={<Radio color="primary"/>}
                                        label="Non"
                                        labelPlacement="start"
                                        classes={{label: classes.text}}
                                    />
                                </RadioGroup>
                            </>
                        )}
                    </Box>
                    <Box display={'flex'} alignItems={'center'} marginBottom={2} gridGap={30}>
                        {isLoading ? (
                            <Skeleton variant="rect" width={150} height={35}/>
                        ) : (
                            <button
                                disabled={isDisabled || isLoadingSend}
                                type="button"
                                onClick={() => setOpen(true)}
                                className="button block">
                                {isEdit ? 'Modifier ma semaine' : t('common.myWeekView.btn')}
                            </button>
                        )}
                        <div className={'clickable-link'}>
                            {/*TODO : Changer par la donné récupérer depuis la team de l'user */}
                            {t('common.myWeekView.plan', {zone: 'Alpha'})}
                        </div>
                    </Box>
                    <div className={'disabled'}>
                        {t('common.myWeekView.info')}
                    </div>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <h2>{isEdit ? 'Confirmation de modification' : 'Confirmation de validation'}</h2>
                    <p>If you disable JavaScript, you will still see me.</p>
                    <div className={classes.wrapper}>
                        <Button
                            style={{color: 'white'}}
                            variant="contained"
                            onClick={() => setOpen(false)}
                            className={`button bg-secondary`}>
                            {'Annuler'}
                        </Button>
                        <Button
                            className={`button ${classes.confirmButton}`}
                            variant="contained"
                            color="primary"
                            disabled={isLoadingSend}
                            onClick={handleSubmit}
                        >
                            {'Confirmer'}
                            {isLoadingSend && <CircularProgress size={20} className={classes.circularProgress}/>}
                        </Button>
                    </div>
                </div>
            </Modal>
        </Grid>
    )
}

export default MyWeek
