import {Box, CardContent, Grid, Typography} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import DefaultImg from "images/booking/default.jpg";
import {MeetingSchema} from "app/models/booking.model";
import {useTranslation} from "react-i18next";
import React, { useMemo} from "react";
import {Carousel} from "app/components/carousel/carousel.component";
import {ServiceTypeEnum} from "app/enums/booking.enum";
import {ICenter} from "app/models/centers.model";

interface IProps {
    meetingRoom: MeetingSchema,
    targetCenter : string,
    centers : ICenter[] | undefined
}

const BookingReadLeftComponent = ({meetingRoom, targetCenter, centers}: IProps) => {
    const {t} = useTranslation()
    const isMeeting = useMemo(() => meetingRoom.serviceType === String(ServiceTypeEnum.MEETING), [meetingRoom])
    const images = useMemo(() => {
        return meetingRoom.images?.length > 0 ? meetingRoom.images : [{
            url : DefaultImg,
            name : t('common.homeView.center.imageAlt'),
            id : 0,
            tags : []
        }]
    }, [meetingRoom.images]);

    const selectedCenter = useMemo(() => {
        return centers?.find((c) => c.id === Number(targetCenter))
    },[centers, targetCenter])

    return (
        <Grid item xs={12} sm={7}>
            <CardContent>
                <Typography variant="h2" color={'primary'} gutterBottom style={{marginBottom: 15}}>
                    {t(meetingRoom.label)}
                </Typography>
                <Box display={'flex'} style={{marginBottom: 15}}>
                    <LocationOnIcon color="primary"/>
                    <Box>
                        <Typography variant="subtitle2" gutterBottom>
                            {isMeeting ? meetingRoom.centerName : selectedCenter?.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {isMeeting ? `${meetingRoom.centerAddress}, ${meetingRoom.centerZipCode} ${meetingRoom.centerCity}, ${meetingRoom.centerCountry}` : selectedCenter?.address}
                        </Typography>
                    </Box>
                </Box>
                {isMeeting && <Box display={'flex'} alignItems={'center'} style={{marginBottom: 15}}>
                    <PersonOutlineIcon color="primary"/>
                    <Typography variant="subtitle2">
                        {meetingRoom.capacity} {t('common.booking_capacity')}
                    </Typography>
                </Box>}
                <Typography variant="body2" gutterBottom style={{marginBottom: 15}}>
                    {meetingRoom.description}
                </Typography>
                <Carousel images={images} />
            </CardContent>
        </Grid>
    )
}

export default BookingReadLeftComponent
