import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {AppContextType} from 'app/types/providers.type'
import {ExceptionEnum} from 'app/enums/exceptions.enum'
import {IAlertMessage} from 'app/models/alert_message.model'
import Session from 'app/libraries/session.lib'
import {SeverityEnum} from 'app/enums/app.enum'
import {ChangePasswordQuery, ForgottenPasswordQuery} from 'app/api/connection.api'
import {IChangePassword} from "../models/account.model";
import DateFormatter from "../formatters/date.formatter";
import {ServiceTypeEnum} from "../enums/booking.enum";

interface IProps {
    children: ReactNode
}

const AppContext = createContext<AppContextType>({
    isConnected: false,
    setIsConnected: () => undefined,
    throwAppException: () => undefined,
    onError: false,
    setOnError: () => undefined,
    alertMessage: undefined,
    setAlertMessage: () => undefined,
    onLogout: false,
    setOnLogout: () => undefined,
    fromApp: false,
    forgottenPasswordUseMutation: undefined,
    changePasswordUseMutation: undefined,
    bookingServiceType: ServiceTypeEnum[ServiceTypeEnum.MEETING],
    setBookingServiceType: () => undefined,
    bookingHours: [],
    bookingHour: {begin: '0', end: '0'},
    setBookingHour: () => undefined,
    bookingDate: DateFormatter.formatUSDate(new Date()),
    setBookingDate: () => undefined,
    bookingDateMinAndMax: {} as {min: string; max: () => string}
})

const AppProvider = ({children}: IProps): JSX.Element => {
    const {t} = useTranslation()
    const queryParams = new URLSearchParams(window.location.search)

    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [onError, setOnError] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<IAlertMessage | undefined>()
    const [onLogout, setOnLogout] = useState<boolean>(false)
    const [fromApp, setFromApp] = useState<boolean>(false)
    const [bookingServiceType, setBookingServiceType] = useState<string>(ServiceTypeEnum[ServiceTypeEnum.FREE_DESK])
    const bookingHours = useMemo<string[]>(() => ['09', '10', '11', '12', '13', '14', '15', '16', '17'], [])
    const [bookingDate, setBookingDate] = useState<string>(DateFormatter.formatUSDate(new Date()))
    const [bookingHour, setBookingHour] = useState({
        begin: '0',
        end: '0'
    })
    const bookingDateMinAndMax = useMemo(() => {
        return {
            min: DateFormatter.formatUSDate(new Date()),
            max: () => {
                let dateLimit = new Date()
                switch (bookingServiceType) {
                    case ServiceTypeEnum[ServiceTypeEnum.MEETING]:
                        dateLimit = new Date(dateLimit.setMonth(dateLimit.getMonth() + 3))
                        break
                    case ServiceTypeEnum[ServiceTypeEnum.FULL_DESKTOP]:
                        dateLimit = new Date(dateLimit.setDate(dateLimit.getDate() + 30))
                        break
                }
                return DateFormatter.formatUSDate(dateLimit)
            }
        }
    }, [DateFormatter, bookingServiceType])

    useEffect(() => {
        const _fromApp = queryParams.get('fromApp')
        setFromApp(_fromApp !== undefined && _fromApp !== null && _fromApp === '1')
    }, [])

    useEffect(() => {
        if (onLogout) {
            setIsConnected(false)
            Session.clear()
            window.location.href = '/login'
            setOnLogout(false)
        }
    }, [onLogout])

    const throwAppException = useCallback((status?: ExceptionEnum, message?: IAlertMessage) => {
        switch (status) {
            case 401:
                if (window.location.pathname.split('/', 2)[1] === 'login') {
                    setAlertMessage({
                        severity: SeverityEnum.ERROR,
                        message: t('common.credentials.invalid'),
                        timeout: 6000
                    })
                    setOnError(true)
                } else {
                    setOnLogout(true)
                }
                break
            case 403:
                setOnError(true)
                if (undefined !== message) {
                    setAlertMessage(message)
                } else {
                    setAlertMessage({
                        severity: SeverityEnum.ERROR,
                        message: t('common.error.unknown'),
                        timeout: 6000
                    })
                    window.location.href = '/home'
                }
                break
            default:
                setOnError(true)
                if (undefined !== message) {
                    setAlertMessage(message)
                } else {
                    setAlertMessage({
                        severity: SeverityEnum.ERROR,
                        message: t('common.error.unknown'),
                        timeout: 6000
                    })
                }
        }
    }, [])

    const forgottenPasswordUseMutation = useMutation<void, unknown, string>(
        'forgottenPassword',
        async (login: string) => await ForgottenPasswordQuery(login),
    )

    const changePasswordUseMutation = useMutation<void, unknown, IChangePassword>(
        'forgottenPassword',
        async (changePassword: IChangePassword) => await ChangePasswordQuery(changePassword),
    )

    const contextValue = useMemo(
        () => ({
            isConnected,
            setIsConnected,
            throwAppException,
            onError,
            setOnError,
            alertMessage,
            setAlertMessage,
            onLogout,
            setOnLogout,
            fromApp,
            forgottenPasswordUseMutation,
            changePasswordUseMutation,
            bookingServiceType,
            setBookingServiceType,
            bookingHours,
            bookingHour,
            setBookingHour,
            bookingDate,
            setBookingDate,
            bookingDateMinAndMax
        }),
        [
            isConnected,
            setIsConnected,
            throwAppException,
            onError,
            setOnError,
            alertMessage,
            setAlertMessage,
            onLogout,
            setOnLogout,
            fromApp,
            forgottenPasswordUseMutation,
            changePasswordUseMutation,
            bookingServiceType,
            setBookingServiceType,
            bookingHours,
            bookingHour,
            setBookingHour,
            bookingDate,
            setBookingDate,
            bookingDateMinAndMax
        ]
    )

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export default AppProvider

export const useApp = (): AppContextType => useContext<AppContextType>(AppContext)
