export function formatParams<T extends Record<string, any>>(params: T): string {
    const paramsFiltered: Record<string, any> = {}

    Object.keys(params).forEach(key => {
        const value = params[key]
        if (value !== undefined && value !== null) {
            paramsFiltered[key] = value
        }
    })

    const searchParams = new URLSearchParams(paramsFiltered)
    const url = searchParams.toString()
    return url ? '?' + url : '';
}
