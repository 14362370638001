import React from 'react'
import HomeSpace from 'images/home/homepage-connected.jpg'
import {Divider, Container, Typography, Grid, useTheme, Card, Chip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {IMAGES} from "app/constants/images";
import {DateRange, RoomService, Apartment} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    chip: {
       color: 'white'
    },
}))

const Home = (): JSX.Element => {
    const theme = useTheme()
    const classes = useStyles()

    return (
        <Container fixed>
            <Grid container>
                <Grid container spacing={3} style={{marginBottom: 20}}>
                    <Grid item xs={12} md={6}
                          style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                        <Typography style={{fontWeight: '500', fontSize: 17, marginBottom: 20}} gutterBottom>
                            Aujourd’hui, vous êtes au bureau :
                        </Typography>
                        <div>
                            <Typography style={{color: theme.palette.primary.main, fontWeight: '500'}} gutterBottom>
                                B03
                            </Typography>
                            <Typography gutterBottom>
                                Bureau - zone verte
                            </Typography>
                            <Typography gutterBottom>
                                2ème étage, Ecoryzon, Sophia Antipolis
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={HomeSpace} alt='Home' width={'100%'} height={202}
                             style={{borderRadius: 4, objectFit: 'cover'}}/>
                    </Grid>
                </Grid>
                <Divider style={{width: '100%', marginBottom: 30}} />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card style={{height: 245, boxShadow:"0px 2px 8px 0px var(--Surface-surface-light-button, #D5EDEC)"}}>
                            <div style={{height: '100%', padding: 17}}>
                                <img src={IMAGES.HOME.TOGETHER} alt={'together'} />
                                <Typography style={{fontWeight: '500', fontSize: 17, marginBottom: 15}}>
                                    Actualités
                                </Typography>
                                <div style={{display: 'flex', alignItems: 'center', gap: 5, marginBottom: 15}}>
                                    <Chip label="20/06" color={'primary'} className={classes.chip} size={'small'}/>
                                    <Typography style={{fontSize: 14}}>
                                        <b>AfterWork</b> à partir de 18h00
                                    </Typography>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                                    <Chip label="24/06" color={'primary'} className={classes.chip} size={'small'}/>
                                    <Typography style={{fontSize: 14}}>
                                        <b>Activité QVCT</b> à partir de 12h00
                                    </Typography>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card style={{
                            height: 245,
                            boxShadow: "0px 2px 8px 0px var(--Surface-surface-light-button, #D5EDEC)",
                            background: "var(--Brand-bk, #F5F9FB)"
                        }}>
                            <div style={{height: '100%', padding: 17}}>
                                <DateRange style={{fontSize: 45}}/>
                                <Typography style={{fontWeight: '500', fontSize: 17, marginBottom: 15}}>
                                    Réserver
                                </Typography>
                                <Typography style={{fontSize: 14}}>
                                    Réservation en ligne de vos salles de réunion, bureaux...
                                </Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card style={{
                            height: 245,
                            boxShadow: "0px 2px 8px 0px var(--Surface-surface-light-button, #D5EDEC)",
                            background: "var(--Brand-bk, #F5F9FB)"
                        }}>
                            <div style={{height: '100%', padding: 17}}>
                                <RoomService style={{fontSize: 45}}/>
                                <Typography style={{fontWeight: '500', fontSize: 17, marginBottom: 15}}>
                                    Services
                                </Typography>
                                <Typography style={{fontSize: 14}}>
                                    Accédez à tous les services : conciergerie digitale, sport et bien-être...
                                </Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Card style={{
                            height: 245,
                            boxShadow: "0px 2px 8px 0px var(--Surface-surface-light-button, #D5EDEC)",
                            background: "var(--Brand-bk, #F5F9FB)"
                        }}>
                            <div style={{height: '100%', padding: 17}}>
                                <Apartment style={{fontSize: 45}}/>
                                <Typography style={{fontWeight: '500', fontSize: 17, marginBottom: 15}}>
                                    Entreprise
                                </Typography>
                                <Typography style={{fontSize: 14}}>
                                    Réservation en ligne de vos salles de réunion, bureaux...
                                </Typography>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Home
