import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
    Box,
    Grid,
    Typography,
    CircularProgress
} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {ImageSchema, MeetingSchema} from 'app/models/booking.model'
import {useOnView} from 'app/providers/onview.provider'
import 'modules/booking/css/booking.scss'
import BookingListFilterComponent from "modules/booking/components/booking/bookingListFilter.component";
import BookingListCardComponent from "modules/booking/components/booking/bookingListCard.component";
import {useApp} from "app/providers/app.provider";
import {useOnLogin} from "app/providers/onlogin.provider";
import DefaultImg from "images/booking/default.jpg";
import {ServiceTypeEnum} from "app/enums/booking.enum";
import Button from "@material-ui/core/Button";

const Booking = (): JSX.Element => {
    const { bookingHour, bookingDate, bookingServiceType } = useApp()
    const {t} = useTranslation()
    const { meetingRoomsUseMutation, getAssets } = useOnView()
    const [meetingRooms, setMeetingRooms] = useState<MeetingSchema[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [offset, setOffset] = useState<number>(0)
    const [isLast, setIsLast] = useState<boolean>(true)
    const firstRender = useRef(true)
    const [isValidDate, setIsValidDate] = useState<boolean>(true)
    const {userCenterId, centers} = useOnLogin()
    const centerIds = useMemo(() => centers !== undefined ? centers.map(center => center.id) : [], [centers])
    const [targetCenter, setTargetCenter] = useState<string>(String(userCenterId))
    const [serviceImages, setServicesImages] = useState<ImageSchema[]>( [{
        url : DefaultImg,
        name : 'image',
        tags : [],
        id : 0
    }]);
    const { fromApp } = useApp()

    const bookingLinkOffice = useMemo<string>(
        () => (fromApp ? '/booking-office?fromApp=1' : '/booking-office'),
        [fromApp]
    )

    useEffect(() => {
        if(!firstRender.current) {
            getMeetingRooms(true).then()
        }
    }, [targetCenter, bookingServiceType])

    useEffect(() => {
        if(!firstRender.current && bookingDate && bookingHour.begin !== '0' && bookingHour.end !== '0' && isValidDate) {
            getMeetingRooms(true).then()
        }
    }, [bookingDate, bookingHour, isValidDate])

    useEffect(() => {
        if(centerIds.length > 0) {
            firstRender.current = false
            getInitialMeetingRooms().then()
        }
    }, [centerIds])

    const getInitialMeetingRooms = useCallback(async () => {
        setIsLoading(true)
        let data = {} as {centerId: number}
        if(centerIds.includes(Number(targetCenter))) {
            data.centerId = Number(targetCenter)
        }
        let response = await meetingRoomsUseMutation?.mutateAsync({
            queryParams: data
        })

        setOffset(response!.actual)
        setMeetingRooms(response!.items)
        setIsLast(response!.last)

        if(response!.items) {
            const images = await getAssets?.mutateAsync({
                type: 'service-types',
                id: Number(response!.items[0].serviceType)
            })
            if (images && images.length > 0) setServicesImages(images)
        }

        setIsLoading(false)

    }, [
        meetingRoomsUseMutation,
        setOffset,
        setMeetingRooms,
        setIsLast,
        setIsLoading,
        targetCenter,
        centerIds
    ])

    const getMeetingRooms = useCallback(async (reset: boolean) => {
        if(reset) {
            setMeetingRooms([])
        }
        let begin = undefined, end = undefined;
        if(bookingDate && bookingHour.begin !== '0' && bookingHour.end !== '0' && isValidDate) {
            begin = `${bookingDate}T${bookingHour.begin}:00:00`
            end = `${bookingDate}T${bookingHour.end}:00:00`
        }
        const centerId = centerIds.includes(Number(targetCenter)) ? Number(targetCenter) : 0
        setIsLoading(true)
        let response = await meetingRoomsUseMutation?.mutateAsync({
            queryParams: {
                offset: reset ? 0 : offset,
                centerId: centerId !== 0 ? centerId : undefined,
                serviceType: bookingServiceType,
                begin,
                end
            }
        })
        setOffset(response!.actual)
        setMeetingRooms((prevState) => reset ? response!.items : [...prevState, ...response!.items])
        setIsLast(response!.last)

        if(response!.items) {
            const images = await getAssets?.mutateAsync({
                type: 'service-types',
                id: Number(response!.items[0].serviceType)
            })
            if (images && images.length > 0) setServicesImages(images)
        }

        setIsLoading(false)

    }, [
        meetingRoomsUseMutation,
        offset,
        setIsLoading,
        setOffset,
        setMeetingRooms,
        setIsLast,
        targetCenter,
        bookingServiceType,
        bookingDate,
        bookingHour,
        isValidDate,
        centerIds
    ])

    const refreshData = useCallback(() => {
        if (
            window.scrollY + window.innerHeight >=
            document.documentElement.scrollHeight &&
            !isLoading &&
            !isLast
        ) {
            getMeetingRooms(false).then()
        }
    }, [isLoading, isLast, getMeetingRooms])

    useEffect(() => {
        window.addEventListener("scroll", refreshData)

        return () => {
            window.removeEventListener('scroll', refreshData)
        };
    }, [refreshData])

    return (
        <>
            <Box>
                {<Typography variant={'caption'} color={'textPrimary'} gutterBottom>
                    {t('common.booking_open_desktop_instead')}
                    <Button variant={'text'} color={'primary'} size={'small'} style={{fontSize : 'inherit', textTransform :'initial'}} href={bookingLinkOffice}>{t('common.click_here_maj')}</Button>
                </Typography>}
                <Typography variant="h2" color={'textPrimary'} gutterBottom>
                    {t('common.bookingView.onlineBooking')}
                </Typography>
                <BookingListFilterComponent
                    mode={ServiceTypeEnum.MEETING}
                    centerIds={centerIds}
                    targetCenter={targetCenter}
                    setTargetCenter={setTargetCenter}
                    isValidDate={isValidDate}
                    setIsValidDate={setIsValidDate}
                />
            </Box>
            <Grid container spacing={3} style={{marginTop: 5}}>
                {!isLoading && meetingRooms.length > 0 && meetingRooms.map((meetingRoom: MeetingSchema) => (
                    <BookingListCardComponent
                        key={meetingRoom.id}
                        meetingRoom={meetingRoom}
                        images={serviceImages}
                    />
                ))}
                {isLoading && (
                    <Box width={'100%'} textAlign={'center'} marginTop={5}>
                        <CircularProgress color="primary" />
                    </Box>
                )}
            </Grid>
        </>
    )
}

export default Booking
