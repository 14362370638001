import {checkResponse, getRequestInit, getUrl, patchRequestInit, postRequestInit} from "./api";
import {IMyPlanningBody, IMyPlannings, IPlanningServiceParams, IPlanningServices} from "../models/planning.model";
import {formatParams} from "../helpers/common.helper";

export const MyPlanningQuery = async (
    dayDate?: string
): Promise<IMyPlannings> => {
    return checkResponse(
        await fetch(getUrl(dayDate === undefined ? `socorpo/v1/planning` : `socorpo/v1/planning?dayDate=${dayDate}`), getRequestInit(true))
    )
}

export const AvailableServicesQuery = async (
    queryParams: IPlanningServiceParams
): Promise<IPlanningServices> => {
    return checkResponse(await fetch(getUrl(`socorpo/v1/planning/services${formatParams(queryParams)}`), getRequestInit(true)))
}

export const CreateMyPlanningQuery = async (
    body: IMyPlanningBody
): Promise<IMyPlannings> => {
    return checkResponse(await fetch(getUrl(`socorpo/v1/planning`), postRequestInit(true, JSON.stringify(body))))
}

export const UpdateMyPlanningQuery = async (
    body: IMyPlanningBody
): Promise<IMyPlannings> => {
    return checkResponse(await fetch(getUrl(`socorpo/v1/planning`), patchRequestInit(true, JSON.stringify(body))))
}
