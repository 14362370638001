import Box from "@material-ui/core/Box";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import React, {Dispatch, SetStateAction, useCallback, useMemo} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {IMyPlannings, IPlanningDays} from "app/models/planning.model";
import dayjs from "dayjs";
import {myPlannings} from "../helpers/formatter.helper";

const useStyles = makeStyles(() => ({
    label: {
        fontSize: 14,
        fontWeight: 500
    },
    icon: {
        height: 14,
        cursor: 'pointer'
    },
    rotate: {
        rotate: '180deg'
    }
}))

interface IProps {
    setIsEdit: Dispatch<SetStateAction<boolean>>
    setIsLoading: Dispatch<SetStateAction<boolean>>
    fetchData: (dayDate?: (string | undefined)) => Promise<IMyPlannings | undefined>
    data: IPlanningDays
    setMyPlanning:  Dispatch<SetStateAction<IPlanningDays>>
}

const WeekComponent = ({setIsEdit, setIsLoading, fetchData, data, setMyPlanning}: IProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const prevMonday = useMemo(() => dayjs(data[0][0].dayDate).subtract(1, 'week').format('YYYY-MM-DD'), [data])
    const nextMonday = useMemo(() => dayjs(data[0][0].dayDate).add(1, 'week').format('YYYY-MM-DD'), [data])
    const thisWeek = useMemo(() => dayjs().startOf('week').format('YYYY-MM-DD') === data[0][0].dayDate, [data])

    const handlePrevWeek = useCallback(async () => {
        setIsLoading(true)
        const response = await fetchData(prevMonday) as IMyPlannings
        setIsEdit(!response.days.some((planning) => planning.clientPlanningId === null))
        setMyPlanning(myPlannings(response))
        setIsLoading(false)
    }, [fetchData, prevMonday])

    const handleNextWeek = useCallback(async () => {
        setIsLoading(true)
        const response = await fetchData(nextMonday) as IMyPlannings
        setIsEdit(!response.days.some((planning) => planning.clientPlanningId === null))
        setMyPlanning(myPlannings(response))
        setIsLoading(false)
    }, [fetchData, nextMonday])

    return (
        <Box
            alignItems={'center'}
            flexDirection={'row'}
            display={'flex'}
            gridGap={7}
            marginBottom={3}
            justifyContent={'flex-end'}
        >
            <ArrowBackIosIcon fontSize='small' className={classes.icon} color={'primary'} onClick={handlePrevWeek}/>
            <Typography variant="body1" className={classes.label}>
                {thisWeek ? t('common.myWeekView.weekComponent.week') : t('common.myWeekView.weekComponent.weekDate', {weekDate: dayjs(data[0][0].dayDate).format('D MMMM')})}
            </Typography>
            <ArrowBackIosIcon fontSize='small' className={`${classes.icon} ${classes.rotate}`} color={'primary'} onClick={handleNextWeek}/>
        </Box>
    )
}

export default WeekComponent

