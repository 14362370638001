import {Divider, Grid, styled, Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

interface IProps {
    hourlyPrice: string
    halfdayPrice: string
    dailyPrice: string
}

const BoldTypography = styled(Typography)({
    fontWeight : 'bold'
})

const SmallSup = styled("sup")({
    fontSize : 10
})

const BookingListCardPricerComponent = ({hourlyPrice, halfdayPrice, dailyPrice}: IProps) => {
    const {t} = useTranslation();

    return (
        <Grid container justifyContent={'space-between'} style={{marginTop: 5}} >
            <Grid item>
                <BoldTypography variant="subtitle1"  color={'primary'}>
                    {hourlyPrice}€ <SmallSup>HT</SmallSup>
                </BoldTypography>
                <Typography variant="body2" >
                    {t('common.global.hour')}
                </Typography>
            </Grid>
            <Divider orientation="vertical"  flexItem />
            <Grid item>
                <BoldTypography variant="subtitle1" color={'primary'}>
                    {halfdayPrice}€ <SmallSup>HT</SmallSup>
                </BoldTypography>
                <Typography variant="body2" >
                    {t('common.global.halfDay')}
                </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
                <BoldTypography variant="subtitle1" color={'primary'}>
                    {dailyPrice}€ <SmallSup>HT</SmallSup>
                </BoldTypography>
                <Typography variant="body2" >
                    Jour
                </Typography>
            </Grid>
        </Grid>
    )
}

export default BookingListCardPricerComponent
