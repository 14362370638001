import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {firstLetterUpper} from "app/helpers/formatter.helper";
import React from "react";

interface IProps {
    officeLabel: string|null
    zoneLabel: string|null
}

const useStyles = makeStyles({
    text: {
        fontWeight: 500,
        fontSize: 14,
        userSelect: 'none'
    },
    subText: {
        fontSize: 12,
        fontWeight: 300,
        color: '#424242',
        fontStyle: 'italic'
    }
})

const OfficeSectionComponent = ({officeLabel, zoneLabel}: IProps) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.text}>
                {t('common.myWeekView.officeSectionComponent.office')}
            </Typography>
            <Typography className={classes.subText}>
                {`${zoneLabel !== null ? t('common.myWeekView.officeSectionComponent.zoneLabel', {name: firstLetterUpper(zoneLabel.toLowerCase())}) + ` ` : ``}${officeLabel !== null ? `(${officeLabel})` : ``}`}
            </Typography>
        </div>
    )
}

export default OfficeSectionComponent
