import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from 'app/views/navbar.view'
import Footer from 'app/views/footer.view'
import Login from 'modules/login/views/login.view'
import Profile from 'modules/profile/views/profile.view'
import ProfileEdit from 'modules/profile/views/profile_edit.view'
import AccountingDocumentsList from 'modules/accountingDocuments/views/accountingdocuments_list.view'
import MembersList from 'modules/members/views/members_list.view'
import DocumentsList from 'modules/documents/views/documents_list.view'
import ContractsList from 'modules/contracts/views/contracts_list.view'
import ContractsDetails from 'modules/contracts/views/contracts_details.view'
import PendingContract from 'modules/contracts/views/pending_contract.view'
import QuotationsCard from 'modules/quotations/views/quotations_card.view'
import PaymentCard from 'modules/payment/views/payment_card.view'
import Credit from 'modules/credit/views/credit.view'
import PaymentResult from 'modules/payment/views/payment_result.view'
import Helpdesk from 'modules/helpdesk/views/helpdesk.view'
import HelpdeskList from 'modules/helpdesk/views/helpdesk_list.view'
import Booking from 'modules/booking/views/booking.view'
import Foodcard from 'modules/foodcard/views/foodcard.view'
import Home from 'modules/home/views/home.view'
import DocumentsDetails from 'modules/documents/views/documents_details.view'
import ForgottenPasswordView from 'modules/login/views/ForgottenPassword.view'
import BookingList from 'modules/booking/views/booking_list.view'
import { useApp } from 'app/providers/app.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'
import PendingContractButtonComponent from 'app/components/pendingContractButton.component'
import 'app/css/app.scss'
import PasswordView from "modules/login/views/password.view";
import {useAppTheme} from "app/providers/apptheme.provider";
import {IMAGES} from "app/constants/images";
import BookingRead from "modules/booking/views/booking_read.view";
import MyWeek from "modules/presence/views/myWeek.view";
import {ServiceTypeEnum} from "app/enums/booking.enum";

const AppView = (): JSX.Element => {
  const { isConnected, onError, setOnError, alertMessage, setAlertMessage, fromApp } = useApp()
  const { pendingContracts } = useOnLogin()

  const [appClass, setAppClass] = useState<string>('app')
  const [mustRefresh, setMustRefresh] = useState<boolean>(false)
  const {isDarkMode} = useAppTheme()
  useEffect(() => {
    let appClassTmp = 'app'
    if (
      window.location.pathname.split('/', 2)[1] === 'login' ||
      window.location.pathname.split('/', 2)[1] === 'forgotten_password' ||
      window.location.pathname.split('/', 2)[1] === 'password'
    ) {
      appClassTmp = 'app login'
    } else if (window.location.pathname.split('/', 2)[1] === 'quotations' && !isConnected) {
      appClassTmp = 'app quotations'
    } else if (window.location.pathname.split('/', 2)[1] === 'payment_result' && !isConnected) {
      appClassTmp = 'app payment_result'
    }
    if (isDarkMode) {
      appClassTmp += ' dark'
    }
    setAppClass(appClassTmp)
  }, [window.location.pathname, isDarkMode])

  useEffect(() => {
    if (mustRefresh) {
      setMustRefresh(false)
      let resource = window.location.pathname.split('/', 2)[1]
      switch (resource) {
        case 'home':
        case 'profile/edit':
        case 'profile':
        case 'helpdesk_list':
        case 'helpdesk':
          window.location.reload()
          break
        default:
          window.location.href = '/home'
      }
    }
  }, [mustRefresh])

  const onAppSnackbarClose = (): void => {
    setOnError(false)
    setAlertMessage(undefined)
  }

  return (
    <Router>
      {isConnected && <div className={isDarkMode ? 'bg dark' : 'bg'} />}
      <div className={appClass} style={{
        backgroundImage: appClass === 'app login' ? `url(${isDarkMode ? IMAGES.BG_LOGIN_DARK : IMAGES.BG_LOGIN})` : undefined
      }}>
        {!fromApp && (
          <div className="navbar dark">
            <Navbar />
          </div>
        )}
        <div className="content">
          <Switch>
            <Route path="/login">
              <Login isDarkMode={isDarkMode} />
            </Route>
            <Route path="/forgotten_password">
              <ForgottenPasswordView isDarkMode={isDarkMode} />
            </Route>
            <Route path="/helpdesk">
              <Helpdesk />
            </Route>
            <Route path="/helpdesk_list">
              <HelpdeskList />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/booking/:id">
              <BookingRead />
            </Route>
            <Route path="/booking">
              <Booking />
            </Route>
            <Route path="/booking-office">
              <BookingRead mode={ServiceTypeEnum.OPEN_DESKTOP}/>
            </Route>
            <Route path="/booking_list">
              <BookingList />
            </Route>
            <Route path="/foodcard">
              <Foodcard />
            </Route>
            <Route path="/quotations">
              <QuotationsCard />
            </Route>
            <Route path="/password">
              <PasswordView isDarkMode={isDarkMode}/>
            </Route>
            <Route path="/payment">
              <PaymentCard />
            </Route>
            <Route path="/credit">
              <Credit />
            </Route>
            <Route path="/payment_result">
              <PaymentResult />
            </Route>
            <Route path="/enterprise/accountingdocuments/list">
              {undefined !== pendingContracts && pendingContracts.length > 0 && (
                <PendingContractButtonComponent />
              )}
              <AccountingDocumentsList individualOnly={false} />
            </Route>
            <Route path="/accountingdocuments/list">
              {undefined !== pendingContracts && pendingContracts.length > 0 && (
                <PendingContractButtonComponent />
              )}
              <AccountingDocumentsList individualOnly={true} />
            </Route>
            <Route path="/enterprise/members/list">
              {undefined !== pendingContracts && pendingContracts.length > 0 && (
                <PendingContractButtonComponent />
              )}
              <MembersList />
            </Route>
            <Route path="/enterprise/documents/list">
              {undefined !== pendingContracts && pendingContracts.length > 0 && (
                <PendingContractButtonComponent />
              )}
              <DocumentsList />
            </Route>
            <Route path="/enterprise/documents/:id/details">
              <DocumentsDetails />
            </Route>
            <Route path="/my_presence">
              <MyWeek />
            </Route>
            <Route path="/enterprise/contracts/list">
              <ContractsList />
            </Route>
            <Route path="/enterprise/contracts/:id/details">
              <ContractsDetails />
            </Route>
            <Route path="/enterprise/contracts/:id/pending">
              <PendingContract />
            </Route>
            <Route path="/profile/edit">
              <ProfileEdit />
            </Route>
            <Route path="/profile/">
              {undefined !== pendingContracts && pendingContracts.length > 0 && (
                <PendingContractButtonComponent />
              )}
              <Profile />
            </Route>
          </Switch>
        </div>
      </div>
      {onError && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={onError}
          autoHideDuration={6000}
          onClose={onAppSnackbarClose}
          message={alertMessage?.message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onAppSnackbarClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
      {isConnected && !fromApp && <Footer />}
    </Router>
  )
}

export default AppView
