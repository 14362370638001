import * as React from 'react';
import Box from "@material-ui/core/Box";
import { CircularProgress, Fade, styled} from "@material-ui/core";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import {ImageSchema} from "app/models/booking.model";
import IconButton from "@material-ui/core/IconButton";
import {useCallback, useState} from "react";

const CarouselNavigationBox = styled(Box)({
    position: 'absolute',
    inset: 6,
    backgroundColor: 'rgba(255,255,255,0)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
})

const CarouselNavButton = styled(IconButton)(({theme}) => ({
    backgroundColor: theme.palette.background.default,
}))

export const Carousel = function ({images, isLoading} : {images : ImageSchema[], isLoading?:boolean}) {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = images.length;

    const handleNext = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, []);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);

    return (
        <Box style={{ flexGrow: 1, position: 'relative' }}>
            <Box sx={{ width: '100%'}}>
                <Fade key={activeStep} in={true} unmountOnExit={true}>
                    {isLoading ?
                        <Box display={'flex'} style={{aspectRatio: '16/9',
                            maxWidth: '100%'}}
                             alignItems={'center'} justifyContent={'center'}><CircularProgress /></Box> :
                        <Box style={{
                            aspectRatio: '3/2',
                            backgroundImage : `url(${images[activeStep].url})`,
                            backgroundSize : 'cover',
                            maxWidth: '100%'
                        }} ></Box>}
                </Fade>
            </Box>
            {maxSteps > 1 &&
                <CarouselNavigation
                    maxSteps={maxSteps}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                />
            }
        </Box>
    );
}

const CarouselNavigation = ({ handleNext, handleBack, activeStep, maxSteps} : { handleNext :  () => void, handleBack : () => void, activeStep : number, maxSteps : number}) => {
    return <CarouselNavigationBox>
        <>
            <CarouselNavButton
                size={'small'}
                onClick={handleBack}
                disabled={activeStep === 0}>
                <KeyboardArrowLeft/>
            </CarouselNavButton>
            <CarouselNavButton
                size={'small'}
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
            >
                <KeyboardArrowRight/>
            </CarouselNavButton>
        </>
    </CarouselNavigationBox>
}
